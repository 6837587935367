import React from "react";
import "./css/hero.css";
import Nav from "./Nav";
import MobileNav from "./MobileNav";
import HeroButton from "./HeroButton";

const Hero = () => {
  return (
    <div className="hero cover-container d-flex w-100 p-3 mx-auto flex-column">
      {/* <MobileNav/> */}
      <header className="mb-auto">
        {/* <Nav/> */}
        <main className="px-3 name-and-mantra">
          <h1>Daniel Sumah.</h1>
          <p className="lead">Product Manager</p>

          <HeroButton />
        </main>
      </header>
    </div>
  );
};

export default Hero;
