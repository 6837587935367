import React from "react";
import { Link } from "react-router-dom";
import "./css/herobutton.css";

const HeroButton = () => {
  return (
    <div className="container-fluid main hero-btn">
      <div className="text-center main-text">
        {/* <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit</h3> */}

        <div className="c2a-btn footer-c2a-btn">
          <div
            className="btn-group btn-group-lg"
            role="group"
            aria-label="Call to action"
          >
            <Link type="button" className="btn btn-default btn-lg" to="/about">
              About me
            </Link>
            <span className="btn-circle btn-or">or</span>
            <Link
              type="button"
              className="btn btn-default btn-lg"
              to="/experience"
            >
              Experience
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroButton;
