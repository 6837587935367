import "./App.css";
import Hero from "./components/Hero";
import Container from "./components/Container";
import Education from "./components/Education";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={Hero} />
        <Route path="/about" component={Container} />
        <Route path="/education" component={Education} />
        <Route path="/experience" component={Experience} />
        <Route path="/portfolio" component={Portfolio} />
      </Router>
    </div>
  );
}

export default App;
