import React from 'react';
import './css/portfolio.css'

const ProductManagement = () =>{
    const pm_portfolio = [
        {
            "product_name":"My-Intern-App",
            "product_image_url":"https://lh3.googleusercontent.com/0DH17w_8wY2wf20MEkvO-9sfrMBCEnXmiW48JEgrL4Eqb_obB-Z2JkwF6Xm2CXNw7Avvb05byia0PPGFeRjJ4RTqryq5oxFtVf2xDtAiMeKFlXjlzkS_UvgUuQz0W5Ig6KXSfu6JpQ=w2400",
            "description":"I contribute code and led the Backend team in the creation of this product that connects students with skills with Employers that need them.",
            "hosted_link":"https://murmuring-meadow-64199.herokuapp.com",
        },
        {
            "product_name":"3smart Technologies",
            "product_image_url":"https://lh3.googleusercontent.com/-PT2E4ckYRFDQQ4I83ENt6pL3GvmQwMAI9lVX3z_In9XZ2zP-H0o_C3t9rw2tuiUdqjp4YfHXQo4keV6_A3bAX24sjYE07AbIXO6OxVAyc-cCcX1W_z5o2D6VY5runBtdMTXKX96zA=w2400",
            "description":"Solved a user tracking problem for 3smart Technology, a Renewable Energy company in Nigeria using their website. | role-played as Product Manager,directing the vision of the product | Contributed code and assisted in the design of the User Interface",
            "hosted_link":"https://3smartech.com/",
        },
        {
            "product_name":"Recipe Search App",
            "product_image_url":"https://lh3.googleusercontent.com/oRbk62B6BhajvvRpWytmqeZZ-n6p2iB7MFJrC_fgLbjTJ6E6P2QMCln8aFZrMaIzelZG-0vdwOEBWDHoHh13L7O40q2JJKThjlQDR2LjoiskOucQzwCU2srwIsFtuDd2uVLcdvay7g=w2400",
            "description":"My sister and some friends love baking and cooking; this app helps them search for recipes for different dishes.",
            "hosted_link":"https://naughty-elion-48b754.netlify.app/",
        },
        {
            "product_name":"CRM App",
            "product_image_url":"https://lh3.googleusercontent.com/VMGS2kkdndlaEBLd8jgOInHQoszJGZeEkL0Z3Mf6yq_JjJgXEEi_dfgsjciG_SlkqHTnqa2kYsW3gSQtKwr-XTI8219n_S9L7tJE6xjNp17aAaAtsOEhKfRvYNkOQ6-zyp1jECcQuQ=w2400",
            "description":"I create a clone of a CRM Application that tracks customer's orders up to their delivery",
            "hosted_link":"",
        },
        {
            "product_name":"Cake Recipe App",
            "product_image_url":"https://lh3.googleusercontent.com/hL-AmR2PXu24YG6B39xk62RJ4ZJnulBwItrBNaUOfUKOie4FZ5hLH49neWp5y9Oio7WkDOjS8a6DQKDDIic9wxvCoNqDJ3MbIttEmLaY_MTCD7E9cWZ46N7Cu3-NJqLUTETZ5uWH2w=w2400",
            "description":"I created an Andriod Mobile App for Cake lovers to get Recipes to to different types of Cakes ",
            "hosted_link":"https://drive.google.com/file/d/1sw5kJMDHsBEIlTHZMFCfvTS1ZgpkB67u/view?usp=sharing",
        },
    ]
    return (
        <div>
            <h5 className="card-title portfolio-header">Product Management and Programming</h5>
            <div className="portfolio-container">

                {pm_portfolio.map(portfolio=>(
                    <div className="card" style={{width: "18rem"}}>
                        <img className="card-img-top" src={portfolio.product_image_url}/>
                        <div className="card-body">
                            <h5 className="card-title">{portfolio.product_name}</h5>
                            <p className="card-text">{portfolio.description}</p>
                            <a href={portfolio.hosted_link} className="btn btn-primary">View Product</a>
                        </div>
                        
                    </div>
                    
                ))}          
            </div>

            
        </div>
        )
}

export default ProductManagement;