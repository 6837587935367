import React from 'react';
import './css/portfolio.css'

const HardwareProductDesign = () =>{
    const product_design_portfolio = [
        {
            
            "product_name":"Industrial Robotic Arm",
            "product_image_url":"https://lh3.googleusercontent.com/3nS7-erpEe_-kUS17c0hCHtbR4r_kS5W0BvZ5zl3cbKVcB_AsoJOzN2qzXBrcDTnkj75EWzqhjijCm2-fugsHMuJ5gUdszDDIkmBIkWnrDeFpa4zGW5DF56538cSNUyCHwwCRat_mQ=w2400",
            "description":"Designed with Fusion360",
        },
        {
            "product_name":"Headphones",
            "product_image_url":"https://lh3.googleusercontent.com/lZN-ybAbuWOX1bqV6XwRgeoFkQ4eUz_kcOJbYOsKMRkd5IhQ_jrpMtMet6GA__5HdFh4q8dStceLXbtLHJ-ZS1E7AcYkTNw5lViqeqIO-Nty8_kb5iF1hyU_I0OB_PDr56kPY4xZVg=w2400",
            "description":"Used Fusion 360 Sculpting feature to create designs of headphone casing",
        },
        {
            
            "product_name":"Valve Body",
            "product_image_url":"https://lh3.googleusercontent.com/e7dFU7ZFYvKl_oqrv7EMxjXtI5lEmg1CpIJq7ZYjLkgFFPePd_A-QjdcUWBPB7HT_-aQZzJuF9gvq7IhyZXbBFQ0qqMm6m7UcjVl9c09jSSba0hiogRIYW2kuTUJakY8PfoqeV6eVw=w2400",
            "description":"A fusion 360 Challenge",
        },
        {
            
            "product_name":"Biodiesel Processor 1",
            "product_image_url":"https://lh3.googleusercontent.com/pQyXML8nrkxPoyTkEBLWWhgNCzDJ5mUTwMtcJqi8HdqCSMkHVXCv3yZRuuT4LjwWwjydyszx0lbkymVaf3waPo6aKvyKIomuqWJvN8_V7X_nXrm31CMqHU2EZUPJ4fJ400Svk1EvTQ=w2400",
            "description":"I Designed this Product from an already made Processor for the purpose of Documentation and Report for a friend's Project",
        },
        {
            
            "product_name":"Car Body",
            "product_image_url":"https://lh3.googleusercontent.com/lJ5So06rYuhEXykyll4Hx-K54WZkMSlIvssb07dL9NauEFKIeeC2JbGDrSPf8-2dw2Mwek5rhl6kPZSUx4cVkk6-EhK5KDbuGJNTyeRyPl6EkZmL-S2zb4sWxfc_Ct2fJ6WjgchDug=w2400",
            "description":"Designed this when learning to use Fusion 360's sculpting feature",
        },
        {
            
            "product_name":"Biodiesel Processor 2",
            "product_image_url":"https://lh3.googleusercontent.com/_eGaYcGv8H3vs7do2fnyYgASzWa9FKbyIg-CLGcUqXSUynHUbh_YwFkZkLW8JEXpuBdqUsgSp9Mddh29BlYIU73EWYJMUMcwqXcoE8XcoUKp8BmMBbfY8J3Id_QwOuLmgKPKqlf5kA=w2400",
            "description":"I Designed this Product from an already made Processor for the purpose of Documentation and Report for a friend's Project",
        },
        {
            "product_name":"3smart Electronics Casing",
            "product_image_url":"https://lh3.googleusercontent.com/ZhxyfaU5xL61S8qxhM-urw5D1pj6PalBmmXLjOb1f7FVeCAX8aXS8zlLVgJBzZaTwohAt93sbipU5vItUBgQ5P9yjvD69P5Q5OWNrAsQBTfLe0PgCWnY4Z2jc0WqeTSZGwQkZ8J4dQ=w2400",
            "description":"Designed the casings of 3smart Technologies's Automatic ChangeOver. The designs are meant to be used as Engineering drawings in the product's manual",
        },
        
        {
            "product_name":"BMW Wheel",
            "product_image_url":"https://lh3.googleusercontent.com/mWKw2XrRQ-RhzX4orYmF3kQlMCvEovCurulqfdchAsSybFkF1v1Rh0sgsnZkFv9oTxLRjkOfIgxHADvr6YEz5zSMQBpwcR6gTuMIgCjiKnB5I36IUiW1BwOW-wiAWQFnibjr_Apqog=w2400",
            "description":"A practice on Inventor",
        },
        {
            "product_name":"Lithophane Stand",
            "product_image_url":"https://lh3.googleusercontent.com/Z-MLTGFNKJAXKoJTc8RROgr1y_475BgXyI9shusJyWbZJq4OkgcO1SFEwfT6U3Dj2qCnzwJemsR11z3FiFqpe1wjRdXwDKdar4ys7WPSSQ40N2bwpFuWkOYsHPYT9BgR6k3t-vGJqA=w2400",
            "description":"I designed this stand to be 3D printed as a stand for a 3D printed cylindrical Lithophane",
        },
        {
            "product_name":"Fan Hub",
            "product_image_url":"https://lh3.googleusercontent.com/PJgu3QGBPjqRRmJRFwIKs1tYOuY0UGfsanVLwSj0AIWWQumVfZ8tyepN3Nx8FAyq6v8jLUMRJZZ-mjYONd8Uui39EoixiTLuitxt1VIyKhidsrI1Mh13IyrlcM0OakUDQxrAE-0u3A=w2400",
            "description":"A simple fan hub",
        },
        {
            "product_name":"Face Mask",
            "product_image_url":"https://lh3.googleusercontent.com/ELACFq7KnTq6Rqr7vOu517MZfFzHmeyRopC0bnB_uoImvr3YNzzkVeSMfGLXsY0UNFfPmTTWm3Qi9_qlynER0B7J8dd1Pu0sWrWovq09SLQXwwAhB0UiSACJSWxWosruo6XvMNnhwQ=w2400",
            "description":"Designed a facemask in a design Challenge organized by S2Pafrica during the wake of Covid 19",
        },
        
        
    ]
    return (
        <div>
            <h5 className="card-title portfolio-header">Hardware Product Design</h5>
            <div className="portfolio-container">

                {product_design_portfolio.map(portfolio=>(
                    <div className="card col-md-12 col-md-4" style={{width: "18rem"}}>
                        <img className="card-img-top" src={portfolio.product_image_url} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">{portfolio.product_name}</h5>
                            <p className="card-text">{portfolio.description}</p>
                        </div>
                    </div>
                ))}
                                
            </div>

            
        </div>
        )
}

export default HardwareProductDesign;