import React from "react";
import SideNav from "./SideNav";
import "./css/container.css";
import { useState } from "react";
import "./education.css";
import Experience from "./Experience";
import MobileNav from "./MobileNav";

const Education = () => {
  const education_history = [
    {
      school: "Nexford University.",
      degree: "Business Communication for Leaders and Managers",
      level: "",
      year: "2023- In progress",
    },
    {
      school: "The University of Lagos Akoka.",
      degree: "BSc - Mechanical Engineering.",
      level: "Graduate",
      year: "2017/18 - 2023",
      grade: "First Class",
    },
    {
      school: "Yaba College of Technology, Yaba.",
      degree: "OND - Mechanical Engineering.",
      level: "ND1-2",
      year: "2014 - 2016",
    },
    {
      school: "CADD CENTRE - Lagos",
      degree: "Diploma in CAD - Mechanical Engineering.",
      level: "",
      year: "2018",
    },
    {
      school: "Logem International College, Ota.",
      degree: "SSCE - Sciences",
      level: "",
      year: "2008 - 2014",
    },
  ];

  return (
    <div className="container-wrapper">
      <MobileNav />

      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Education</h1>
            </div>

            <div className="container-fluid">
              {education_history.map((education) => (
                <div className="education-details">
                  <p className="school">{education.school}</p>
                  <p className="degree">{education.degree}</p>
                  <p className="level">
                    {education.level}{" "}
                    {education.grade && ": " + education.grade}
                  </p>
                  <p className="school-year">{education.year}</p>
                  <br />
                </div>
              ))}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Education;
