import React from "react";
import "./css/mobilenav.css";
import { Link } from "react-router-dom";

const MobileNav = () => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <Link class="navbar-brand" to="/">
          Daniel Sumah
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li class="nav-item">
                            <Link class="nav-link active" aria-current="page" to="/">
                                Home
                            </Link>
                        </li> */}
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/about">
                About me
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/education">
                Education
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link active"
                aria-current="page"
                to="/experience"
              >
                Experience
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/portfolio">
                Portfolio
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;
