import React from "react";
import SideNav from "./SideNav";
import "./css/container.css";
import { useState } from "react";
import "./education.css";
import "./css/experience.css";
import MobileNav from "./MobileNav";

const Experience = () => {
  const work_history = [
    {
      company: "Shuttlers",
      logo: "https://lh3.googleusercontent.com/pw/ADCreHdMwZhQ_Px4u-yXa8Cg32XClBlp_-tgDF7BWqk2ZY1hYUUQLs8DQrs3B9sFgD4IfSj_J7jAq_9kOEcKbxnPwPh9F0YpEdvy9379MSTxQ9ZLNI-Wf10=s200-p-k",
      position: "Product Lead",
      duration: "Feb 2024 - Present",
      location: "Lekki-Lagos, Nigeria",
      job_description: [
        "Utilizing research and data analytics to enhance the internal operations app.",
        "leading the Development and Implementation of features that maximizes revenue, improves stickiness of Vehicle Partners and improves onboarding of B2B customers.",
        "Implemented Product Analytics for informed product decisions and aligning product vision with the company’s vision.",
        " Collaborating cross-functionally to launch and improve products for revenue generation and management.",
        "Improving the product and leadership capabilities of a Product Intern.",
      ],
    },
    {
      company: "Shuttlers",
      logo: "https://lh3.googleusercontent.com/pw/ADCreHdMwZhQ_Px4u-yXa8Cg32XClBlp_-tgDF7BWqk2ZY1hYUUQLs8DQrs3B9sFgD4IfSj_J7jAq_9kOEcKbxnPwPh9F0YpEdvy9379MSTxQ9ZLNI-Wf10=s200-p-k",
      position: "Product Specialist",
      duration: "Feb 2023 - Jan, 2024",
      location: "Lekki-Lagos, Nigeria",
      job_description: [
        "Managed a suite of 3 main web products: Operations App, Business Dashboard, and Vehicle Partners App.",
        "Creating tools that automates revenue collection, management and disbursement for Shuttlers and it's bus partners.",
        "Improving the user experience for our B2B clients and their staffs onboarding into Shuttlers.",
        "Provided Product leadership to the B2B product contributing to the $4M raise to accelerate shared mobility in Nigeria.",
        "Improved security of Internal Apps by Implemented security: Access Control and improving Audit trail.",
        "Created product roadmap and oversaw product lifecycle from ideation to launch.",
        "Provided mentorship and leadership to a Product Intern.",
        "Collaborated with cross-functional teams (Customer experience, operations, sales, finance etc).",
      ],
    },
    {
      company: "Shuttlers",
      logo: "https://lh3.googleusercontent.com/pw/ADCreHdMwZhQ_Px4u-yXa8Cg32XClBlp_-tgDF7BWqk2ZY1hYUUQLs8DQrs3B9sFgD4IfSj_J7jAq_9kOEcKbxnPwPh9F0YpEdvy9379MSTxQ9ZLNI-Wf10=s200-p-k",
      position: "Junior Product Manager",
      duration: "Feb 2022 - Jan 2023",
      location: "Lekki-Lagos, Nigeria",
      job_description: [
        "Created and maintained product documentations (PRDs, guides, presentations).",
        "Identified user pain points and enhanced UI/UX for increased user satisfaction.",
        "Guided and supervised the development of a Product intern.",
        "Led a team of engineers, designer, and QA engineers in creating and launching the Shuttlers Vehicle Partner's app",
        "Product Led the creation of two new apps: Vehicle Partners app and Bus Marshal app ensuring that 6 of 6 shuttlers stakeholder has an app.",
      ],
    },
    {
      company: "AXA Mansard",
      logo: "https://lh3.googleusercontent.com/6QOQB2bgPXdN7tHhX0ak40L48xzxz3oLM0oyWdp3QjnDbEbwfu0AjpYbkJkUx9bjllYe6cqJutEPybApuhccly_szfHafVvEl23_iX6fgh4o_mG1j9V0ouIr_6RNj4fepcVb7_sfPA=s208-p-k",
      position: "Product Designer + Code",
      duration: "Sept 2021 - Feb 2023",
      location: "Lagos, Nigeria",
      job_description: [
        "Working on the ReVamping and extending of the UI and UX of already existing AXA products",
        "Frontend Development of Designed UIs using React Native",
      ],
    },
    {
      company: "3Smart Technology.",
      logo: "https://lh3.googleusercontent.com/s6bt0TnH33erp5n7IuV-MX7_4pecj_2drKotKTrJKAg7pUM2C-dBsHmO9PppfWKXomARckKbjb1VfY-39EIKDPaA-WmsHr94p3Vlvbki-5WeJBouXknIWoxX6M9f5biJECtAMtlz6Q=s208-p-k",
      position: "BD / Digital Marketing Manager",
      duration: "Aug 2020 - Aug 2022",
      location: "Lagos, Nigeria",
      job_description: [
        "Creating and ensuring Implementation of strategies to achieve financial growth and to expand business reach with a concentration on locally produced Automatic Transfer Switches.",
        "Planning, tracking and testing of the training of the Marketing Team Members to ensure that their Skills are at par with their responsibilities.",
      ],
    },

    {
      company: "Perxels",
      logo: "https://lh3.googleusercontent.com/DeStBO3Pw-dgZwdPHkmjkAY1E3Yg5unMZ7BEEyHwE1ZPFP3OSSRdzonvsEgIc0EyeVnLUpLgSKJiTq7JyjMRghDCOhV5ozrXxDlgcKHIiXuV-3aq9WTXKaqB6PtQSf-KFfyIoI2G8w=s208-p-k",
      position: "Product Manager",
      duration: "Jul 2020 - Jan 2021",
      location: "Lagos, Nigeria",
      job_description: [
        "Worked closely with Engineering and Mangement to ensure Products are built to meet revenue goals.",
        "Served as Administrator for the Management of Students for each Cohort.",
        "Sourcing and testing of tools that makes the Team's work flow swimmingly.",
        "Creating and execution of Digital Marketing Campaigns.",
      ],
    },

    {
      company: "Nigerian Foundries Group",
      logo: "https://lh3.googleusercontent.com/TcEcI-JZVttnl9Ua8mdxneqXW1jEG7HqazjLNY7-euOW_fVKMTtQkEOwsNmDNM5xkmfuDIsV6Gpx75Ok8tKMpdE9i6-JV4OZOhFV7evbGYpc1Q0wSfURb4EsFPu3eDC-PJRDvk2_jA=s208-p-k",
      position: "Engineering Design Intern.",
      duration: "Mar 2020 - Nov 2020",
      location: "Ogun, Nigeria.",
      job_description: [
        "3D Modelling and Testing of Designs of New Products using Fusion360.",
        "Industrial use of Additive Manufacturing in Foundry Technology.",
      ],
    },

    {
      company: "Hash Analytics",
      logo: "https://lh3.googleusercontent.com/2tMTit9uqbJFKzMbdE60e9sNVpsSjuKA3vGg1xua6_soGkit5EnxR8uUajMaCR-KKfCGwF8oZJL9vgSsxPSnMxS2fwwAN6oyC2r9eq1pvWllOBNp532Ni4y9iFA7f3TFwbxnyGQhYw=s208-p-k",
      position: "Data Analytics Intern",
      duration: "Nov 2019 - Jan 2020",
      location: "Virtual",
      job_description: [
        "Worked on a Dataset of Employees(past and present) to predict Employee Attrition.",
      ],
    },

    {
      company: "3Smart Technology.",
      logo: "https://lh3.googleusercontent.com/s6bt0TnH33erp5n7IuV-MX7_4pecj_2drKotKTrJKAg7pUM2C-dBsHmO9PppfWKXomARckKbjb1VfY-39EIKDPaA-WmsHr94p3Vlvbki-5WeJBouXknIWoxX6M9f5biJECtAMtlz6Q=s208-p-k",
      position: "Digital Marketing Manager.",
      duration: "March 2019 - Aug 2020",
      location: "Lagos, Nigeria",
      job_description: [
        "Plan and manage the Company’s Social Media Presence.",
        "Create Social Media Contents.",
      ],
    },

    {
      company: "AIESEC in Isfop.",
      logo: "https://lh3.googleusercontent.com/oyB3f0nAwnTcuhCnAjHqYq9nIkNHysFmyWfsfDgsMiCmPPSsZd5yIAJGfMLaxRlTWMtl0kZi0ERHcGrC8kFMkJjyBQZsM31L_Nyc4cFaKoS1tKbGcaGD9pM1C75J2Lk3kdgvmubQvw=s208-p-k",
      position: "Team Lead - Digital Marketing",
      duration: "2018 - 2019",
      location: "Benin Republic.",
      job_description: [
        "Ensuring publications meet AIESEC branding rules.",
        "Designing of campaign flyers",
        "Management of three team members.",
      ],
    },

    {
      company: "3Smart Technologies.",
      logo: "https://lh3.googleusercontent.com/s6bt0TnH33erp5n7IuV-MX7_4pecj_2drKotKTrJKAg7pUM2C-dBsHmO9PppfWKXomARckKbjb1VfY-39EIKDPaA-WmsHr94p3Vlvbki-5WeJBouXknIWoxX6M9f5biJECtAMtlz6Q=s208-p-k",
      position: "Industrial Trainee",
      duration: "Mar 2017 - Mar 2019",
      location: "Lagos, Nigeria.",
      job_description: [
        "Production of Automatic Transfer Switches for Generators.",
        "Installation of BackUp/Reneable Energy Systems.",
        "Digital Marketing.",
      ],
    },

    {
      company: "Ascent ltd.",
      logo: "https://lh3.googleusercontent.com/gi7su6yFkzAP6rek2k9P2kcXfH2gyUNsOR--rFRa5fNugmWQq9BOLWp4tX8q8EAp7LMPXOCtoJxsXg3_JKHWOYMNbun8X0u9ScY1Z_yb_C5qLbKM9Y6YCynTnMRUNJo6u9wvKxIwsw=w2400",
      position: "Industrial Trainee",
      duration: "May 2016 - Aug 2016",
      location: "Lagos, Nigeria.",
      job_description: [
        "Provision of Integrity Servies on Industrial Boilers.",
        "Create Social Media Media contents.",
      ],
    },
  ];

  return (
    <div className="container-wrapper">
      <MobileNav />
      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Experience</h1>
            </div>

            <div>
              {work_history.map((job) => (
                <div className="row">
                  <div className="col-2 company-image">
                    <img src={job.logo} alt="" />
                  </div>

                  <div className="col-md-8 company-details">
                    <p className="position">{job.position}</p>
                    <p className="company">
                      <span>
                        <img
                          className="small-company-image"
                          src={job.logo}
                          alt=""
                        />
                      </span>
                      {job.company}
                    </p>
                    <p className="duration">{job.duration}</p>
                    <p className="location">{job.location}</p>
                    <p className="row job-description">
                      <p className="col-1"></p>
                      <p className="col-11">
                        {job.job_description.map((description) => (
                          <li>{description}</li>
                        ))}
                      </p>
                    </p>
                  </div>

                  <div className="col-2"></div>
                </div>
              ))}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Experience;
