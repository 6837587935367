import React from 'react';
import './css/portfolio.css'

const ThreeDPrinting = () =>{
    return (
        <div>
            <h5 className="card-title portfolio-header">3D Printing</h5>
            <div className="portfolio-container">

                
                    <div className="card" style={{width: "18rem"}}>
                        <div className="card-body">
                            <h5 className="card-title">Click the button below to view my amazing 3D printings on Instagram</h5>
                            <a href="https://www.instagram.com/bubble.3d/" className="btn btn-primary">Go to Instagram</a>
                        </div>
                    </div>
                                
            </div>

            
        </div>
        )
}

export default ThreeDPrinting;