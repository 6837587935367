import React from "react";
import "./sidenav.css";
import { Link } from "react-router-dom";

const SideNav = () => {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to="/">
              <span data-feather="file"></span>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to="/about">
              About me
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to="/education">
              Education
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to="/experience">
              Experience
            </Link>
          </li>
          {/* <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/portfolio">
                                        Portfolio
                                    </Link>                                    
                                </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;
