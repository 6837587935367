import React from "react";
import SideNav from "./SideNav";
// import profile_pic from './images/daniel.png'
import profile_pic from "../components/images/daniel.png";
import "./css/container.css";
import Nav from "./Nav";
import MobileNav from "./MobileNav";

const Container = () => {
  return (
    <div className="container-wrapper">
      <MobileNav />

      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">About</h1>
            </div>

            <div className="container-fluid about-section">
              <div className="row">
                <div className="col-md-5">
                  <img
                    src={profile_pic}
                    className="img-thumbnail"
                    alt="Daniel Sumah"
                  />
                </div>

                <div className="col-md-7">
                  <p class="text-md-start name">
                    <span className="label">Name:</span>
                    Daniel Sumah
                  </p>
                  <p class="text-md-start description">
                    Product Manager at Shuttlers | MBA with AI specialization in
                    View
                  </p>
                  <p class="text-md-start description">
                    Passionate Product Manager at Shuttlers, where I blend my
                    technical expertise in design, frontend, and backend
                    development to drive scheduled ride-sharing solutions.
                    Currently working toward an MBA with an AI specialization at
                    Nexford University to deepen my leadership, management, and
                    strategic insights.
                  </p>
                  <p class="text-md-start description">
                    Experienced mentor, guiding interns to success. Outside of
                    work, I fancy swimming, building products, mentoring,
                    watching football, and engaging in meaningful conversations.
                    Let's connect!
                  </p>
                </div>
              </div>
            </div>

            <p class="text-md-start">
              <span className="label">Core Values</span>
            </p>
            <p class="text-md-start">
              <hr className="col rules" />
              <hr className="col-6 rules" />
            </p>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md">
                  <p className="value-header">Integrity</p>
                  <p className="value-description">
                    Say what you do and do what you say.
                  </p>
                </div>
                <div className="col-md">
                  <p className="value-header">Growth</p>
                  <p className="value-description">
                    I value being in a Team where everyone consistently shows
                    growth.
                  </p>
                </div>
                <div className="col-md">
                  <p className="value-header">Respect</p>
                  <p className="value-description">
                    I respect myself and others for who they are and what they
                    can do. We might be different but we are together because we
                    bring something to the table.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="badge-base LI-profile-badge"
              data-locale="en_US"
              data-size="medium"
              data-theme="dark"
              data-type="VERTICAL"
              data-vanity="daniel-sumah-44796368"
              data-version="v1"
            >
              <a
                class="badge-base__link LI-simple-link"
                href="https://www.linkedin.com/in/daniel-sumah/"
                style={{ paddingBottom: "24px" }}
              >
                View my linkedin profile
              </a>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Container;
