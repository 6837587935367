import React from 'react';
import ProductManagement from './ProductManagement';
import SideNav from './SideNav';
import Education from './Education';
import HardwareProductDesign from './HardwareProductDesign';
import ThreeDPrinting from './ThreeDPrinting';
import MobileNav from './MobileNav';

const Portfolio = () =>{
    return(
        <div className="container-wrapper">
            <MobileNav/>

            <div className="container-fluid">
                <div className="row">
                    
                    <SideNav/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Portfolio</h1>
                        </div>

                        <ProductManagement/>
                        <HardwareProductDesign/>
                        <ThreeDPrinting/>
                    </main>

                </div>
            </div>

        </div>
    )
}

export default Portfolio;